.willSlideInRight {
  opacity: 0;
  transform: translateX(-300px);
}

.willSlideInLeft {
  opacity: 0;
  transform: translateX(300px);
}

.slideinright {
  opacity: 0;
}

.slideinright-enter {
  opacity: 0;
  /* position: "absolute"; */
  /* left: "-600px"; */
}

.slideinright-enter-active {
  opacity: 1;
  transform: translateX(0px);
  transition: opacity 600ms, transform 600ms;
  /* transition: left 2s; */
}

.slideinright-enter-done {
  transform: translateX(0px);
  opacity: 1;
}

.slideinleft {
  opacity: 0;
}

.slideinleft-enter {
  opacity: 0;
  transform: translateX(300px);
  /* position: "absolute"; */
  /* left: "-600px"; */
}

.slideinleft-enter-active {
  opacity: 1;
  transform: translateX(0px);
  transition: opacity 600ms, transform 600ms;
  /* transition: left 2s; */
}


.slideinleft-enter-done {
  opacity: 1;
  transform: translateX(0px);
}
