.App {
  text-align: center;
  background-color: #fff;
}

/* ************ */

.ReactModal__Body--open {
  /* Empêche le scroll quand un modal est ouvert */
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

input[type="date"] {
	position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: -30px;
    position: absolute;
    /*right: 0;*/
    top: 0;
    width: calc(100% + 30px);
}